<template>
    <section class="contact-us">
        <div class="container">
            <div class="row justify-content-center">
                <div class="text-center head col-6">
                    <h3 class="font-weight-bold title">تواصل معنا</h3>
                    <p class="sub-title font-weight-bold">
                        سهولة التواصل معنا تتيح لك مميزات كثيرة فقط قم بالتواصل معنا وسف نقوم بالرد عليكم مباشر
                    </p>
                </div>
            </div>
            <div class="sub-head mb-4">
                <p>معلومات الاتصال</p>
            </div>
        </div>
        <div class="contact-details">
            <div class="container">
                <div class="row">
                    <div class="col-lg-9 col-sm-12">
                        <form class="mb-5">
                            <div class="row">
                                <div class="col">
                                    <div class="form-group row">
                                        <label class="col-3 col-form-label">الخط الموحد</label>
                                        <div class="col-9">
                                            <p class="blank-text">{{support.phone}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group row">
                                        <label class="col-4 col-form-label">البريد الالكتروني</label>
                                        <div class="col-8">
                                            <p class="blank-text">{{support.email}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group row">
                                        <label class="col-3 col-form-label">العنوان</label>
                                        <div class="col-9">
                                            <p class="blank-text">{{support.address}}
                                                <span>
														<img src="'/assets/images/location.png'" alt="">
													</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="sub-head mb-4">
                                <p>او ارسل استفسارك هنا</p>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="form-group row">
                                        <label for="firstName" class="col-sm-3 col-form-label">الاسم الاول</label>
                                        <div class="col-sm-9">
                                            <input
                                                    v-model.trim="user.first_name"
                                                    type="text" class="form-control"
                                                    id="firstName"
                                            >
                                            <small class="form-text text-danger" v-if="!$v.user.first_name.required">
                                                يجب ادخال الاسم الاول
                                            </small>
                                            <small class="form-text text-danger" v-if="!$v.user.first_name.minLength">
                                                اقل عدد حروف
                                                {{$v.user.first_name.$params.minLength.min}}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group row">
                                        <label for="lastname" class="col-sm-3 col-form-label">الاسم الاخير</label>
                                        <div class="col-sm-9">
                                            <input type="text" v-model='$v.user.last_name.$model' class="form-control"
                                                   id="lastname">
                                            <small class="form-text text-danger" v-if="!$v.user.last_name.required">
                                                يجب ادخال الاسم الاخير
                                            </small>
                                            <small class="form-text text-danger" v-if="!$v.user.last_name.minLength">
                                                اقل عدد حروف
                                                {{$v.user.last_name.$params.minLength.min}}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="form-group row">
                                        <label for="mobile" class="col-sm-3 col-form-label">رقم الجوال</label>
                                        <div class="col-sm-9">
                                            <input type="number" v-model='$v.user.phone.$model' class="form-control"
                                                   id="mobile">
                                            <small class="form-text text-danger" v-if="!$v.user.phone.required">
                                                يجب ادخال رقم الجوال
                                            </small>
                                            <small class="form-text text-danger" v-if="!$v.user.phone.minLength">
                                                اقل عدد حروف
                                                {{$v.user.phone.$params.minLength.min}}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group row">
                                        <label for="email" class="col-sm-3 col-form-label">البريد الالكتروني</label>
                                        <div class="col-sm-9">
                                            <input type="text" v-model='$v.user.email.$model' class="form-control"
                                                   id="email">
                                            <small class="form-text text-danger" v-if="!$v.user.email.required">
                                                يجب ادخال البريد الالكتروني
                                            </small>
                                            <small class="form-text text-danger" v-if="!$v.user.email.email">
                                                البريد الالكترونى غير صحيح
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label for="discount">عندك كوبون خصم ؟</label>
                                        <div class="col-lg-8 col-sm-12">
                                            <textarea id="discount" v-model='$v.user.message.$model'
                                                      class="form-control"
                                                      rows="5"></textarea>
                                            <small class="form-text text-danger" v-if="!$v.user.message.required">
                                                يجب ادخال الرساله
                                            </small>
                                            <small class="form-text text-danger" v-if="!$v.user.message.minLength">
                                                اقل عدد حروف
                                                {{$v.user.message.$params.minLength.min}}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <a type="button" @click="sendMessage" class="contact-btn">ارسال</a>
                        </form>
                    </div>
                    <div class="col-3 hidden-sm-down">
                        <img :src="'/assets/images/ScreenshotRequesInfo.png'" alt="">
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import {minLength, email, required} from 'vuelidate/lib/validators'
    import GeneralInfo from "../mixin/GeneralInfo";

    export default {
        name: "Contact",
        mixins: [GeneralInfo],
        data() {
            return {

                user: {
                    first_name: '',
                    last_name: '',
                    email: '',
                    full_name: '',
                    phone: '',
                    message: '',
                }
            };
        },
        validations: {
            user: {
                first_name: {required, minLength: minLength(2)},
                last_name: {required, minLength: minLength(2)},
                phone: {required, minLength: minLength(5)},
                email: {required, email},
                message: {required, minLength: minLength(10)},
            }
        },
        mounted() {
            this.getSupportData();
        },
        computed: {
            fullName: function () {
                return this.user.first_name + ' ' + this.user.last_name;
            }
        },
        methods: {
            sendMessage() {
                if (this.$v.$invalid) {
                    this.swal({
                        title: "خطأ!",
                        text: "قم بتعبئة البيانات كامله وبشكل صحيح",
                        icon: "error",
                        button: "حسنا",
                    });
                    return;
                }
                let user = this.user;
                user.full_name = this.fullName;
                user.msg_type = 'support';
                this.$http.post('/support', user)
                    .then(response => {
                        this.user = {first_name: '', last_name: '', email: '', phone: '', message: ''};
                        this.swal({
                            title: "تمام بنجاح!",
                            text: response.data.Message,
                            icon: "success",
                            button: "حسنا",
                        });
                    })
                    .catch(err => {
                        console.log(err.data, 'Error')
                    });
            },
            getSupportData() {
                this.$http.get('/support')
                    .then(response => {
                        this.support = response.data.Data;
                    })
                    .catch(err => {
                        console.log(err.data)
                    });
            },

        }
    }
</script>

<style scoped>

</style>