import Vue from 'vue'
import Vuex from 'vuex'
import Home from './modules/home'
import CategoryItems from './modules/categories_data';
import MyStore from './modules/my_store';
import NearYou from "./modules/near_you";
import Social from "./modules/social";
import search_offer from "@/store/modules/search_offer";
Vue.use(Vuex)

export const store = new Vuex.Store({
    modules: {
        Home,
        CategoryItems,
        MyStore,
        NearYou,
        Social,
        search_offer
    }
});
export default store;