import axios from 'axios';

import Geocoder from "@pderas/vue2-geocoder";
import Vue from "vue";
Vue.use(Geocoder, {
    defaultCountryCode: null, // e.g. 'CA'
    defaultLanguage:    null, // e.g. 'en'
    defaultMode:        'lat-lng',
    googleMapsApiKey:   'AIzaSyDxl30OMsze17Ha_1gUZ0R0ZOG2FwCqsno'
});
const state = {
    home: [],
    home_offers:[],
    location:{},
    geoCode: 'SA',
    loader: false
};

const getters = {
    homeCategories: (state) => state.home.categories,
    homeStores: (state) => state.home.stores,
    homeOffers: (state) => state.home_offers,
    gallery: (state) => state.home.gallery,
    myGeoLocation: (state) => state.location,
    getGeoCode : (state) => state.geoCode,
    getLoader: (state) => state.loader
};

const actions = {
    async fetchHome({ commit }, data) {
        const response = await axios.get('/home?lat='+data.lat + '&lng='+data.lng);
        commit('initHome', response.data.Data);
    },

    async fetchGeoLocation({commit},data) {
        commit('geoLocation', data)
    },

    async fetchOffers({commit}) {
        const response = await axios.get('/offers');
        commit('initHomeOffers', response.data.Data);
    },

    async fetchGeoCode({commit}, data) {

        Vue.$geocoder.setDefaultCountryCode();
        Vue.$geocoder.send(data, res => {
            commit('geoCode',res)
        })
    },

}

const mutations = {
    initHome: (state, home) => (state.home = home),
    initHomeOffers:(state, offers) => (state.home_offers =offers),
    geoLocation:(state, data) => (state.location = data),
    geoCode:(state, data) => (state.geoCode = data.results[0] ? data.results[0].address_components[0].short_name: ''),
    changeLoader:(state, data) => (state.loader = data)
}

export default {
    state,
    getters,
    mutations,
    actions
}