<template>
  <!-- Slider -->
  <section class="slide-locality">
    <div class="container">

      <hooper style="height: 200px" :centerMode="true" :settings="hooperSettings"  :infiniteScroll="true">
        <slide style="padding: 0;" v-for="(slide, indx) in storeSlider" :key="indx" :indx="indx">
          <img width="95%" height="100%" :src="slide">
        </slide>
        <hooper-navigation slot="hooper-addons"></hooper-navigation>
      </hooper>
    </div>
  </section>
</template>

<script>
import {Hooper, Navigation as HooperNavigation, Slide} from "hooper";
import {mapGetters} from 'vuex'
export default {
  name: 'MySlider',
  components: {
    Hooper,
    Slide,
    HooperNavigation
  },
  computed:{
    ...mapGetters(['storeSlider'])
  },
  data() {
    return {
      hooperSettings: {
        itemsToShow: 3,

      }
    }
  },
  created() {

  }

}
</script>

<style>

</style>