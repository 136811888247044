<template>
  <div>
    <!-- Offers -->
    <section class="offers">

      <div class="container">

        <div class="title">
          <h3 class="font-weight-bold">اقوي العروض</h3>
        </div>
        <div class="row">
          <div :class="{'col-md-6 ': index == 0 , 'col-md-3 mt-3': index > 0}"  v-for="(offer,index) in homeOffers" :key="offer.id">
            <div class="card mb-3 box-shadow" v-if="index < 3">
              <img class="card-img-top"
                   :src="offer.image"
                   data-holder-rendered="true" >
              <div class="card-body p-2">
                <div class="d-flex justify-content-between align-items-center header">
                  <small>
                    <div class="ribbon-wrapper">
                      <div class="gxlow">&nbsp;</div>
                      <div class="ribbon-front" v-if="offer.discount_value">
                        خصم {{offer.discount_type == 'value' ? offer.discount_value : offer.discount_value + ' %'}}
                      </div>
                      <div class="ribbon-edge-topleft" v-if="offer.discount_value"></div>
                    </div>
                  </small>
                  <h5 class="card-title  text-right"><router-link :to="'/' + offer.store.id + '/store'">{{ offer.store.name }}</router-link></h5>
                </div>

                <p class="mb-0 card-text text-right">
                  {{offer.description}}
                </p>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
export default {
  name: "Offers",
  data() {
    return {
      offers: [],
    }
  },
  created() {
    this.fetchOffers();
  },
  computed:{
    ...mapGetters(['homeOffers']),
  },
  methods:{
    ...mapActions(['fetchOffers'])
  }

}
</script>

<style>
.offers .card-img-top{
  height: 250px;
}

    @media (max-width: 767.98px) { 
      .offers{
        padding: 0px;
      }
    }

</style>