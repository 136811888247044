<template>
    <footer class="footer" style="position: static; bottom: 0; width: 100%">
        <div class="container">
            <div class="row">

                <div class="col-sm-2 ">
                    <div class="footer-widget">
                        <ul class="p-0">
                            <li>
                                <router-link :to="'/join-request'">الانضمام الينا</router-link>
                            </li>
                            <li>
                                <router-link :to="'/contact-us'">اتصل بنا</router-link>
                            </li>
                            <li>
<!--                                <router-link :to="'/contact-us'">من نحن</router-link>-->
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-sm-2">
                    <div class="footer-widget">
                        <ul class="p-0">
                            <li>
                                <router-link to="/request-card">طلب بطاقة</router-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-sm-2">
                    <div class="footer-widget">
                        <ul class="p-0">
                            <li v-for="page in pages" :key="page.id">
                                <router-link :to="'/page/'+page.id">{{page.title}}</router-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-sm-2">
                    <div class="footer-widget">
                        <ul class="p-0">
                            <li><a href="tel:920020905">920020905</a></li>
                            <li><a href="mailto:info@firstcard.sa">info@firstcard.sa</a></li>
                            <li><a >  الرياض - حي العليا شارع الامير محمد بن عبدالعزيز </a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-sm-2">
                    <div class="footer-widget font-weight-normal">
                        <ul class="p-0">
<!--                            <li><a href="tel:920020905">{{support.phone}}</a></li>-->
<!--                            <li><a href="mailto:info@firstcard.sa">{{support.email}}</a></li>-->
<!--                            <li><a href="#">{{support.address}}</a></li>-->
                        </ul>
                    </div>
                </div>
                <div class="col-sm-2">
                    <div class="footer-widget">
                        <h5 class="title">متوافر الان على</h5>
                        <a href="https://play.google.com/store/apps/details?id=app.com.firstcard">
                            <img class="store_img" :src="'/assets/images/playStore.jpg'" alt="">
                        </a>
                        <a href="https://apps.apple.com/eg/app/البطاقة-الاولى/id1289381887">
                            <img height="55" width="155" :src="'/assets/images/appleStore.png'" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
    import GeneralInfo from "../../mixin/GeneralInfo";

    export default {
        name: 'Footer',
        mixins:[GeneralInfo],
        data() {
            return {
                pages: [
                    {
                        id: null,
                        title: null,
                    }
                ]
            }
        },
        mounted() {
            this.getPages();
            this.getSupportData()
        },
        methods: {
            getPages() {
                this.$http.get('/pages')
                    .then(response => {
                        this.pages = response.data.Data;
                    })
                    .catch(err => {
                        console.log(err.data)
                    });
            }
        }
    }
</script>

<style scoped>
    .store_img{
        width: 160px !important;
        height: 60px;
    }
        @media (max-width: 767.98px) { 

    .footer-widget{
            margin-bottom: 0px;
             margin-top: 0px;
    }}
</style>