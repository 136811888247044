import axios from 'axios';
const state = {
    searchOffers :[],
    is_last: false
}

const getters = {
    getSearchOffers : (state)=> state.searchOffers,
    get_is_last: (state) => state.is_last
}

const actions = {
    async offer_search({commit},data) {
        const response = await axios.post('/search', data);

        if(!response.data.Data) {
            commit('initLastPage', true)
        }else {
            commit('initLastPage', false)
        }

        if(data.first) {
            commit('fetchSearchOffer', response.data.Data)
        }else if(!data.first && response.data.Data) {
            commit('addSearchOffer', response.data.Data);
        }
    }
}

const mutations = {
    fetchSearchOffer:(state, data) => (state.searchOffers = data),
    addSearchOffer: (state, data) => (state.searchOffers = state.searchOffers.concat(data)),
    initLastPage:(state, data) => (state.is_last = data)
}

export default {
    state,
    getters,
    mutations,
    actions
}