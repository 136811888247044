<template>
  <div>
    <my-slider></my-slider>
    <my-stores></my-stores>
    <hr>
    <my-offers></my-offers>
    <about-card></about-card>
  </div>
</template>
<script>
import Slider from '../components/Main/Slider';
import Stores from '../components/Main/Store';
import Offers from '../components/Main/Offer';
import AboutCard from '../components/Main/AboutCard';

import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'Home',
  components: {
    'my-slider': Slider,
    'my-stores': Stores,
    'my-offers': Offers,
    'about-card': AboutCard,
  },
  data() {
    return {
      coordinates:{}
    }
  },
  computed: {
    ...mapGetters(['myGeoLocation', 'getGeoCode'])
  },
  created() {
      this.coordinates = this.myGeoLocation;
      this.fetchHome(this.coordinates)
  },
  methods: {
    ...mapActions(['fetchHome', 'fetchGeoLocation'])
  }
}
</script>
<style>

</style>