<template>
    <section class="contact-us">
        <div class="container">
            <div class="row justify-content-center">
                <div class="text-center head col-6">
                    <h3 class="font-weight-bold title">{{item.title}}</h3>
                    <!--                    <p class="sub-title font-weight-bold">-->
                    <!--                        سهولة التواصل معنا تتيح لك مميزات كثيرة فقط قم بالتواصل معنا وسف نقوم بالرد عليكم مباشر-->
                    <!--                    </p>-->
                </div>
            </div>
            <!--            <div class="sub-head mb-4">-->
            <!--                <p>معلومات الاتصال</p>-->
            <!--            </div>-->

        </div>
        <div class="contact-details">
            <div class="container">
                <div v-html="item.body">
                </div>
            </div>
        </div>
    </section>

</template>

<script>
    export default {
        name: "Page",
        data() {
            return {
                item: {
                    id: '',
                    body: '',
                    title: ''
                }
            }
        }, watch: {
            '$route'(to) {
                this.item.id = to.params.id;
                this.getPage()
            }
        },
        mounted() {
            this.item.id = this.$route.params.id;
            this.getPage();
        },
        methods: {
            getPage() {
                this.$http.get('/pages/' + this.item.id)
                    .then(response => {
                        this.item = response.data.Data;
                    })
                    .catch(err => {
                        console.log(err.data)
                    });
            }
        }
    }
</script>

<style scoped>

</style>