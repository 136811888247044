<template>
  <div>
    <section class="near-you-map">
      <div class="container">
        <div class="row">
          <GmapMap
              :center="center"
              :zoom="7"
              style="width:100%; height: 550px"
          >
            <GmapMarker
                :key="index"
                v-for="(m, index) in marks"
                :position="m.position"
                :clickable="true"
                :draggable="true"
                @click="center=m.position"
            />
          </GmapMap>
        </div>
      </div>
    </section>
    <!-- resturant Offer -->
    <section class="resturant">
      <div class="container">
        <div class="title mb-5">
          <h3 class="font-weight-bold">الاقرب اليك </h3>
        </div>
        <div class="row">
          <div class="col-lg-3 col-md-6 col-sm-12" v-for="store in getNearStores" :key="store.id">
            <div class="card mb-3">
              <img class="card-img-top"
                   :src="store.image"
                   data-holder-rendered="true">
              <div class="card-body p-2">
                <div class="d-flex justify-content-between align-items-center header">
                  <h5 class="card-title  text-right"><router-link :to="'/place/'+store.slug">{{ store.name }}</router-link></h5>
                </div>

                <div class="d-flex justify-content-between align-items-center location">
                  <p>
                    {{ store.address }}
                  </p>
                  <p>
                    <span class="arround">يبعد عنك</span> <span>{{ store.distance }} km</span>
                  </p>
                </div>
                <div class="d-flex card-footer justify-content-between align-items-center">
                  <a href="#" class="view">
                    <span class="fa-eye fas" aria-hidden="true"></span> 22
                  </a>
                  <a href="#" class="share">
                    <span class="fa-share-alt fas " aria-hidden="true"></span>
                  </a>
                  <a href="#" class="map">
                    <!-- <span class="fa-map-marker-alt fas" aria-hidden="true"></span> -->
                    <img :src="'/assets/images/location-pin.png'" alt="">
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

export default {
  name: 'NearYou',
  data() {
    return {
      center:{
        lat:24.774265,
        lng:46.738586
      },
      marks:[]
    }
  },
  created() {
    this.fetchNearStores(this.myGeoLocation).then(() => {
      this.center.lat = this.myGeoLocation.lat
      this.center.lng = this.myGeoLocation.lng
      this.addMarks();
    })

  },
  computed: {
    ...mapGetters(['myGeoLocation', 'getNearStores'])
  },
  methods: {
    ...mapActions(['fetchNearStores']),
    addMarks() {
      this.getNearStores.forEach((value) => {
          let mark = {position : {lat:10, lng:10}}
          mark.position.lat = parseFloat(value.lat);
          mark.position.lng = parseFloat(value.lng);
          this.marks.push(mark)
      })
    },

  }
}
</script>

<style>

</style>