import axios from 'axios';

const state = {
    store: [],
}

const getters = {
    getStore :(state) => state.store.storeObj,
    storeSlider: (state) => state.store.storeObj.slider,
    storeBranches: (state) => state.store.branches,
    storeOffers:(state) => state.store.offers
}

const actions = {
    async fetchStore({ commit }, id) {
        const response = await axios.get('/stores/' + id);
        commit('initStore', response.data.Data);
    }
}

const mutations = {
    initStore: (state, data) => (state.store = data)
}

export default {
    state,
    getters,
    mutations,
    actions
}