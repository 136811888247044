<template>
    <div>
        <!-- Locality -->
        <section class="locality">
            <div class="container">
                <div class="title">
                    <h3 class="font-weight-bold">المراكز</h3>
                </div>
                <div class="row">
                    <div class="col-md-3 col-6 p-1" v-for="store in homeStores" :key="store.id">
                        <div class="card mb-3 box-shadow card-height">
                            <img class="card-img-top img-height img-fluid"
                                 :src="store.image"
                                 data-holder-rendered="true">
                            <div class="card-body p-2 mt-2" style="height: 30%">
                                <div class="d-flex justify-content-between "
                                     style="height: 30%"
                                >

                                    <h5 class="card-title  text-right">
                                        <router-link :to="'/place/' + store.slug">{{store.name}}</router-link>
                                    </h5>
                                </div>

                                <p class="mb-0 card-text text-right mt-4">
                                    {{store.address}}
                                </p>
                                <div class="d-flex card-footer justify-content-center align-items-center p-0" :class="{'parent_no_distance': store.distance == 0}">
                                    <a href="#" class="share" :class="{'no_distance': store.distance == 0}">
                                        <span class="fa-share-alt fas" aria-hidden="true"></span>
                                    </a>
                                    <small v-if="store.distance > 0" class="text-muted pr-3" >{{ store.distance }} k.m</small>
                                    <router-link v-if="store.distance > 0" :to="'/'+ store.id + '/store/map'" class="map pr-1"  style="margin-left: -19px;">
                                        <span class="fa-map-marker-alt fas" aria-hidden="true"></span>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
    import {mapGetters} from 'vuex';

    export default {
        name: 'Store',
        computed: {
            ...mapGetters(['homeStores', 'myGeoLocation'])
        }
    }
</script>
<style scoped>
    .img-height {
        height: 60%;
    }

    .card-height {
        height: 430px;
    }

    .card-footer {
        position: absolute;
        bottom: 0;
        width: 86%;
    }

    .card-footer a {
        margin: 1px;
    }

    .no_distance {
      margin: auto !important;
    }

    .parent_no_distance {
      width:100%
    }

    @media (max-width: 767.98px) { 
        .card-title a{
    font: normal normal bold 11px/17px Cairo;        }
    .card-body p{
font: normal normal bold 11px/17px Cairo; 
    }
    .card-height img{
        height: 40%;
    }
    .card-height {
        height: 270px;
    }
    .card-footer{
            width: 76%;
    }
     }


</style>