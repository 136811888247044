<template dir="rtl">
  <div>
    <!-- resturant Offer -->
    <section class="resturant" v-infinite-scroll="loadMore" infinite-scroll-disabled="busy"
             infinite-scroll-immediate-check="false" infinite-scroll-distance="10">
      <div class="container">
        <div class="title mb-5">
          <div class="row">
            <div class="col-md-5 mb-2 mb-md-0">
              <h3 class="font-weight-bold">{{ category_name }}</h3>
            </div>
            <div class="col-9 col-md-6">
              <multiselect placeholder="اختر المدينه" selectedLabel="اختير" deselectLabel="احذف" v-model="value" selectLabel="اضغط لاختيار هذا العنصر" :multiple="true" :options="allCities" label="title" track-by="id">
                <span slot="noOptions">اللائحة فارغة</span> <span slot="noResult">لا توجد نتيجة</span>  
              </multiselect>
            </div>
            <div class="col-md-1 col-2">
              <button class="btn btn-primary" @click="getCategoryItems">بحث</button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3 col-sm-6 col-6 div-height mt-4 pl-0 pr-0 p-1" v-for="item in category_items" :key="item.id">
            <div class="card mb-3 card-height">
              <img class="card-img-top img-height"
                   :src="item.image"
                   data-holder-rendered="true">
              <div class="card-body p-2 content-height">
                <div class="d-flex justify-content-between align-items-center header" style="height: 78%"
                >
                  <!--                  <h5 class="card-title  text-right" @mouseover="truncate(false)" @mouseleave="truncate(true)">-->
                  <h5 class="card-title  text-right mt-4">
                    <router-link :to="'/place/'+item.slug">
                      {{ item.name }}
                      <!--                      {{ item.name.length > 20 && truncate_name ? item.name.substring(0, 20) + '...' : item.name }}-->
                    </router-link>

                  </h5>
                </div>

                <div class="d-flex justify-content-between align-items-right location" style="height: 65%">
                  <p class="">
                    {{ item.address.substring(0, 50) }}
                    <!--                    {{item.address.length > 20 && truncate_address ? item.address.substring(0, 20) + '...' : item.address}}-->
                  </p>
                </div>
                <div class="d-flex card-footer justify-content-between align-items-center p-0"
                     :class="{'no_distance_parent': item.distance}">

                  <a href="#" class="share p-0" :class="{'no_distance': !item.distance}">
                    <span class="fa-share-alt fas " aria-hidden="true"></span>
                  </a>
                  <div class="row pl-3" v-if="item.distance">
                    <span style="color: #707070;">km {{ ' ' + item.distance }}</span>
                    <router-link :to="'/'+ item.id + '/store/map'" class="map">
                      <img :src="'/assets/images/location-pin.png'" class="pr-1" alt="">
                    </router-link>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import {mapGetters, mapActions} from 'vuex';
import infiniteScroll from 'vue-infinite-scroll'
import Multiselect from 'vue-multiselect'

export default {
  name: 'StoreCategories',
  directives: {infiniteScroll},
  components: {Multiselect},
  data() {
    return {
      data: {},
      category_id: '',
      truncate_name: true,
      truncate_address: true,
      category_name: '',
      value:null,
      cities_ids:''

    }
  },
  computed: {
    ...mapGetters(['category_items', 'homeCategories', 'myGeoLocation', 'get_is_last', 'getLoader', 'allCities']),
  },
  watch: {
    '$route'(to) {
      this.data.category_id = to.params.id;
      this.category_id = to.params.id;
      this.data.page = 1
      this.data.first = true
      this.data.selected_cities = '';
      this.fetchCategoryItems(this.data).then(() => {
        this.catgoryName()
      });
      this.value = null
    }
  },
  created() {
    this.category_id = this.$route.params.id,
        this.data.category_id = this.category_id;
    this.data.page = 1
    this.data.first = true
    this.data.selected_cities = '';
    this.data.lat = this.myGeoLocation.lat;
    this.data.lng = this.myGeoLocation.lng;
    this.fetchCategoryItems(this.data).then(() => {
      this.catgoryName()
    });
    this.fetchCities();
  },
  methods: {
    ...mapActions(['fetchCategoryItems', 'fetchCities']),
    truncate(value) {
      this.truncate_name = value;
    },
    truncateAddress(value) {
      this.truncate_address = value;
    },
    loadMore() {
      this.data.page = this.data.page + 1
      this.data.first = false
      if (!this.get_is_last) {
        this.fetchCategoryItems(this.data);
      }
    },
    catgoryName() {
      this.homeCategories.forEach(value => {
        if (value.id == this.category_id) {
          this.category_name = value.name
        }
      })
    },
    getCategoryItems(){
      this.cities_ids = '';
      this.value.forEach((val, index) => {
          if(index == 0) {
            this.cities_ids = val.id;
          }else {
            this.cities_ids = this.cities_ids + ',' +val.id;
          }
      });
      this.data.selected_cities = this.cities_ids;
      this.data.first = true;
      this.fetchCategoryItems(this.data)
    }

  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.img-height {
  height: 60%;
}

.card-height {
  height: 95%;
}

.div-height {
  height: 500px;
}

.content-height {
  height: 30%;
}

.resturant .card .card-footer a {
  margin: 1px 25px;
}

.no_distance {
  margin: auto !important;
}

.no_distance_parent {
  width: 100%
}
.multiselect .multiselect__tags .multiselect__input{
  padding : 0px !important;
  border:0px solid #ddd !important;
}

    @media (max-width: 767.98px) { 

  .resturant .card .card-footer a {
    margin: 0px;
  }

     }

</style>