<template>
  <section class="request-card">
    <div class="container">
      <!--            <div class="row justify-content-center">-->
      <!--                <div class="text-center head col-6">-->
      <!--                    <h3 class="font-weight-bold title">طلب بطاقة</h3>-->
      <!--                    <p class="sub-title font-weight-bold">زيارة احد فروعنا او الاتصال بالرقم الموحد وطلب بطاقة او طلبها-->
      <!--                        عن طريق الموقع او التطبيقات الخاصة بالجوال او الاتصال بأحد المناديب والتوصيل مجانا والدفع عند-->
      <!--                        الاستلام</p>-->
      <!--                    <p class="sub-title"> رسوم البطاقة</p>-->
      <!--                    <p class="card-price">-->
      <!--                        <span class="badge badge-light">{{card_price}}</span> ريال-->
      <!--                    </p>-->
      <!--                </div>-->
      <!--            </div>-->
      <!--            <div class="sub-head mb-4">-->
      <!--                <p>اكمل البيانات التالية واحصل على بطاقتك</p>-->
      <!--                <p>وسيتم التواصل معك فور الاشتراك وتعبئة البيانات المطلوبة</p>-->
      <!--            </div>-->

    </div>
    <div v-if="!show_iframe" class="request-details">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-sm-12">
            <form class="mb-5">
              <div class="row">
                <div class="col">
                  <div class="form-group row">
                    <label for="fullName" class="col-sm-3 col-form-label">الاسم ثلاثي</label>
                    <div class="col-sm-9">
                      <input type="text" v-model="request.full_name" class="form-control"
                             id="fullName">
                      <small class="form-text text-danger" v-if="!$v.request.full_name.required">
                        الرجاء ادخال الاسم ثلاثي
                      </small>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group row">
                    <label for="mobileNo" class="col-sm-3 col-form-label">رقم الجوال</label>
                    <div class="col-sm-9">
                      <input type="text" v-model="request.phone" class="form-control"
                             id="mobileNo">
                      <small class="form-text text-danger" v-if="!$v.request.phone.required">
                        الرجاء ادخال رقم الجوال
                      </small>
                      <small class="form-text text-danger" v-if="!$v.request.phone.numeric">
                        الرجاء ادخال رقم الجوال مكون من ارقام
                      </small>
                      <small class="form-text text-danger" v-if="!$v.request.phone.minLength">
                        الرجاء ادخال رقم الجوال لا يقل عن 9 ارقام
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group row">
                    <label for="birthdate" class="col-3 col-form-label">تاريخ الميلاد</label>
                    <div class="col-9">
                      <input type="date"
                             v-model="request.birthdate"
                             class="d-flex flex-row-reverse form-control pl-0 text-right"
                             id="birthdate">
                      <small class="form-text text-danger" v-if="!$v.request.birthdate.required">
                        الرجاء ادخال تاريخ الميلاد
                      </small>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group row">
                    <label class="col-3 col-form-label">الجنس</label>
                    <div class="col-9">
                      <div class="form-check form-check-inline">
                        <input class="form-check-input"
                               v-model="request.gender"
                               name="gender" type="radio"
                               id="genderMale" value="male">
                        <label class="form-check-label" for="genderMale">ذكر</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input"
                               v-model="request.gender"
                               name="gender" type="radio"
                               id="genderFemale" value="female">
                        <label class="form-check-label" for="genderFemale">انثى</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group row">
                    <label for="fullName" class="col-md-12 sub-head"><p>تحتاج نوصلها لك ؟</p></label>
                    <div class="col-md-3">
                      <select v-model="need_deliv" class="form-control">
                        <option :value="true">نعم</option>
                        <option :value="false">لا</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="need_deliv">
                <div class="sub-head">
                  <p>وين تحب نوصلها</p>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group row">
                      <label for="Location" class="col-3 col-form-label">المدينة</label>
                      <div class="col-9">
                        <select class="form-control"
                                v-model="request.city"
                                id="Location">
                          <option v-for="city in cities" :key="city.id">{{ city.title }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group row">
                      <label for="address" class="col-3 col-form-label">الحى</label>
                      <div class="col-9">
                        <input type="text"
                               v-model="request.district"
                               class="form-control" id="address">
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group row">
                      <label for="card_notes" class="col-3 col-form-label">العنوان</label>
                      <div class="col-9">
                                              <textarea rows="3"
                                                        v-model="request.card_notes"
                                                        class="form-control" id="card_notes"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="sub-head">
                <p>عندك كوبون خصم ؟</p>
              </div>

              <div class="form-group">
                <div class="row">
                  <div class="mr-2 col-6">
                    <input type="text"
                           v-model="request.promo_code"
                           class="form-control" id="discount">
                  </div>
                  <div class="col-2">
                    <button type="button" @click="preRequestCard(request.promo_code)" class="btn btn-warning">
                      فحص
                    </button>
                  </div>
                </div>
              </div>

              <div class="col-6 row" v-if="message&&request.promo_code">
									<span class="badge badge-warning pull-left">
										{{ message }}
									</span>
              </div>

              <div class="clearfix"></div>
              <div class="summary-title">
                <p>ملخص الطلب</p>
              </div>
              <!-- summary -->
              <div class="summary">
                <div class="row" v-for="price in prices" :key="price.type">
                  <div class="col-3">
                    <p>{{ price.title }}</p>
                  </div>
                  <div class="col-9">
                    <p>{{ price.value }}
                      ريال</p>
                  </div>
                </div>
              </div>
              <div class="sub-head mt-4">
                <div class="row">
                  <div class="col-md-1.2">
                    <p>طريقة الدفع</p>
                  </div>
                  <div class="col-md-6">
                    <small class="form-text text-danger"
                           v-if="!$v.request.payment_method_id.required">
                      (الرجاء ادخال طريقة الدفع)
                    </small>
                  </div>
                </div>

              </div>

              <div class="col">
                <div class="form-group row">
                  <div class="col-12">
                    <div class="row" v-if="loaded">
                      <div class="col-md-4 mt-2 mt-md-0" v-for="payment in payment_methods" :key="payment.id">
                        <img :class="{'add-border': isChecked(payment.id)}" @click="selectPayment(payment.id)" :src="payment.image" width="100%" style="height: 67px">

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <button type="button" style="border: none" @click="sendRequest()" class="request-btn">طلب بطاقة</button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="show_iframe" class="container">
      <iframe
          :src="url"
          height="450"
          width="500%"
          type="application/html"
          frameborder="0"
      ></iframe>
    </div>
  </section>
</template>

<script>
import {
  minLength,
  // email,
  required,
  numeric
} from 'vuelidate/lib/validators'
import {mapActions, mapGetters} from 'vuex';

export default {
  name: "RequestCard",
  data() {
    return {
      loaded:false,
      show_iframe:false,
      url:'',
      code: "",
      message: "",
      today_date: '',
      need_deliv: false,
      request: {
        full_name: null,
        promo_code: null,
        phone_code: null,
        phone: null,
        city: null,
        district: null,
        birthdate: null,
        card_notes: null,
        payment_method_id: null
      },
      payment_methods: [{
        id: 0,
        image: '',
        name: '',
        type: '',
      }],
      prices: [{
        color: "",
        title: "",
        type: "",
        value: 0
      }],
      card_price: 0,
      cities: [{
        id: null,
        title: null
      }],
      coordinates: {},
      geoCode: ''
    }
  },
  computed: {
    ...mapGetters(['myGeoLocation', 'getGeoCode'])
  },
  created() {
    if(this.$route.query.status == 'payment-success') {
      this.swal({
        title: "عملية ناجحة",
        text: "تم اتمام طلبك بنجاح",
        icon: "success",
        button: "حسنا",
      });
      this.$router.push({name:'request-card'})
    } else if(this.$route.query.status == 'not-success') {
      this.swal({
        title: "حدث خطاء",
        text: "لم تتم عملية الدفع",
        icon: "error",
        button: "حسنا",
      });
      this.$router.push({name:'request-card'})
    }

    this.coordinates = this.myGeoLocation;
    this.fetchGeoCode(this.coordinates);
    this.geoCode = this.getGeoCode;
    let date = new Date();
    this.request.birthdate = date.getFullYear().toString() + '-' + (date.getMonth() + 1).toString().padStart(2, 0) +
        '-' + date.getDate().toString().padStart(2, 0);
  },
  mounted() {
    this.preRequestCard(this.code);
    this.getCountry();
  },
  validations: {
    request: {
      full_name: {required},
      promo_code: {minLength: minLength(1)},
      phone_code: {required},
      phone: {required, numeric, minLength:minLength(9)},
      // city: {required},
      // district: {required},
      birthdate: {required},
      payment_method_id: {required}
    }
  },
  methods: {
    ...mapActions(['fetchHome', 'fetchGeoLocation', 'fetchGeoCode']),
    preRequestCard(code) {
      this.$http.post('/pre-request-card', {code: code})
          .then(response => {
            this.message = response.data.Message;
            let data = response.data.Data;
            this.payment_methods = data.payment_methods;
            this.prices = data.prices;
            this.card_price = data.prices[0].value;
            this.loaded = true;
          })
          .catch(() => {

          })
    },

    async getCountry() {
      const {data} = await this.$http.get('/country/' + this.geoCode);
      let countryId = data.Data.id;
      this.request.phone_code = data.Data.phone_code;
      await this.$http.get('/getCategoryFilterData/' + countryId)
          .then(response => {
            this.cities = response.data.Data;
          }).catch(() => {

          })

    },
    sendRequest() {
      if (this.$v.$invalid) {
        this.swal({
          title: "خطأ!",
          text: "قم بتعبئة البيانات كامله وبشكل صحيح",
          icon: "error",
          button: "حسنا",
        });
        return;
      }
      this.$http.post('/request-card', this.request)
          .then(response => {

            if(response.data.Code == 400) {
              this.swal({
                title: "قم بتعبئة البيانات كامله وبشكل صحيح",
                text: response.data.Message,
                icon: "warning",
                button: "حسنا",
              });
            }else {
              this.request = {
                full_name: null, promo_code: null, phone_code: null, phone: null, city: null,
                district: null, birthdate: null, card_notes: null, payment_method_id: null
              };
              this.show_iframe = true;
              this.url = response.data.Data.url;
              // window.open(response.data.Data.url, '_self')
            }
          })
          .catch(() => {

          })
    },
    isChecked(id) {
      if(this.request.payment_method_id == id) {
        return true;
      }
      return false;
    },
    selectPayment(id) {
      this.request.payment_method_id = id;
    }
  },
}
</script>

<style scoped>
  .add-border{
    border: 2px solid blue !important;
  }
  img {
    border-radius: 24px;
  }
</style>