<template>
  <div id="app" v-if="loaded">
    <div class="overlay" v-if="getLoader">
      <vue-loaders-line-scale class="to-center" color="red" scale="2"></vue-loaders-line-scale>
    </div>
    <my-nav></my-nav>
    <div class="site-content">
      <my-header></my-header>
      <router-view></router-view>
      <my-footer></my-footer>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import MyNav from './components/Layouts/Nav';
import MyHeader from './components/Layouts/Header';
import MyFooter from './components/Layouts/Footer';

export default {
  name: 'App',
  data() {
    return {
      loaded: false,
      coordinates: {
        lat: 0,
        lng: 0
      }
    }
  },
  components: {
    'my-header': MyHeader,
    'my-nav': MyNav,
    'my-footer': MyFooter
  },
  computed: {
    ...mapGetters(['getLoader'])
  },
  created() {
    this.$getLocation({})
        .then(coordinates => {
          this.coordinates = coordinates
          this.fetchGeoLocation(coordinates)
          this.fetchGeoCode(this.coordinates)
        })
        .then(() => {
          this.fetchHome(this.coordinates)
          this.fetchGeoCode(this.coordinates)
          this.loaded = true
        })
        .catch(() => {
          this.fetchGeoCode(this.coordinates)
          this.fetchGeoLocation(this.coordinates)
          this.fetchHome(this.coordinates);
          this.loaded = true
        })
  },
  methods: {
    ...mapActions(['fetchHome', 'fetchGeoLocation', 'fetchGeoCode'])
  }
}
</script>
<style>
.overlay {
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

.to-center {
  position: absolute;
  top: 50%;
  left: 50%

}
</style>