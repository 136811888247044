import axios from 'axios';

const state = {
    social: [],
}

const getters = {
    getSocial :(state) => state.social,

}

const actions = {
    async fetchSocial({ commit }) {
        const response = await axios.get('/social');
        commit('initSocial', response.data.Data);
    }
}

const mutations = {
    initSocial: (state, data) => (state.social = data)
}

export default {
    state,
    getters,
    mutations,
    actions
}