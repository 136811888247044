<template>
    <section class="about-card">

        <div class="container">

            <div class="title">
                <h3 class="font-weight-bold">مميزات البطاقة الاولي</h3>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <div class="body">
                            <ul class="about-list mr-5 pb-5 pt-5">
                                <li>لا يشترط سن محدد</li>
                                <li>متاح لجميع الجنسيات</li>
                                <li>ساري المفعول لمده سنه</li>
                                <li>عدد مرات الاستخدام غير محدد</li>
                                <li>البطاقة الاولي هي اول بطاقة توفر لك خصومات لكل المجالات (الطبية والتجارية والترفيهية)</li>
                            </ul>
                            <div class="d-flex justify-content-center mb-4">

                                <h4>الانضمام الينا كصاحب نشاط او مؤسسة</h4>
                            </div>
                            <div class="d-flex justify-content-center">
                                <router-link to="/request-card" class="btn btn-primary btn-lg" href="#">اطلب بطاقتك</router-link>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6 col-xs-12">
                        <div class="aboutus-banner float-left mt-2">
                            <img src="/assets/images/shop.png" alt="">
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "AboutCard"
    }
</script>

<style scoped>
    .about-list{
        margin-right: 0px  !important;
    }
</style>