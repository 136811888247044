import VueRouter from 'vue-router';
import Vue from "vue";
import Home from '../views/Home'
import CategoryData from '../views/CategoryData'
import StoreShow from '../views/Store'
import Contact from "../views/Contact";
import RequestCard from "../views/RequestCard";
import NearYou from "../views/NearYou"
import Search from "../components/Main/Search"
import JoinRequest from "../views/JoinRequest";
import Page from "../views/Page";


Vue.use(VueRouter);
const router = new VueRouter({
    mode: "history",
    routes: configRoutes()// short for `routes: routes`
});

function configRoutes() {
    return [
        {
            path: '/',
            component: Home,
        },
        {
            path: '/:id/category',
            component: CategoryData
        },
        {
            path: '/:id/store/:key?',
            component: StoreShow
        },
        {
            path: '/place/:id',
            component: StoreShow
        },
        {
            path: '/contact-us',
            component: Contact
        },
        {
            path: '/request-card',
            name:'request-card',
            component: RequestCard
        },
        {
            path: '/join-request',
            component: JoinRequest
        },
        {
            path: '/near-you',
            component: NearYou
        },
        {
            path: '/search/:keyword',
            name: 'my_search',
            component: Search
        },
        {
            path: '/page/:id',
            component: Page
        }
    ];
}

export default router;