<template>
  <div class="tab-pane" id="offers" role="tabpanel">
    <!-- Offers -->
    <div class="info-offers" v-for="offer in storeOffers" :key="offer.id">
      <!-- start item -->
      <div class="item-offers">
        <div class="row">
          <div class="col-lg-2 col-sm-12">
            <img :src="offer.image" alt="">
          </div>
          <div class="col-lg-10 col-sm-12">
            <p class="offer-title">{{offer.title}}</p>
            <p class="offer-price"> السعر قبل {{ offer.offer_before_price }} ريال</p>
            <p class="offer-price"> السعر بعد {{ offer.offer_after_price }} ريال</p>
            <p class="offer-desc">{{offer.description}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'storeOffers',
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['storeOffers'])
  }
}
</script>

<style>

</style>