import Vue from 'vue'
import App from './App.vue'
import router from './router';
import VueRouter from 'vue-router';
import axios from 'axios';
import * as VueGoogleMaps from 'vue2-google-maps'
import VueGeolocation from 'vue-browser-geolocation';
import { store } from './store/store';
import swal from 'sweetalert';
import Vuelidate from 'vuelidate';
import Geocoder from "@pderas/vue2-geocoder";
import 'vue-loaders/dist/vue-loaders.css';
import VueLoaders from 'vue-loaders';
import interceptorsSetup from './middleware/interceptor'
import VueCarousel from 'vue-carousel';
 
Vue.use(VueCarousel);

Vue.use(Vuelidate);


// add these before Vue is instantiated
window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
// axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
// axios.defaults.headers.post['accept'] = 'application/json';
axios.defaults.headers.common['Accept'] = 'application/json';
// axios.defaults.headers.common['accept'] = 'application/json';
axios.defaults.headers.common['accept-language'] = 'ar';
axios.defaults.headers.common['app-version'] = '1';
axios.defaults.headers.common['device-name'] = navigator.platform;
axios.defaults.headers.common['device-os-version'] = '13';
axios.defaults.headers.common['device-udid'] = '1231321321321321321';
axios.defaults.headers.common['device-type'] = 'web';
axios.defaults.headers.common['device-push-token'] = 'Not Allowed';
axios.defaults.headers.common['Device-OS-Version'] = navigator.platform;



Vue.prototype.$http = axios;
Vue.prototype.swal = swal;


// axios.defaults.baseURL = 'http://192.168.0.7/projects/remote-alola-backend/api';

// axios.defaults.baseURL = 'http://localhost/projects/remote-alola-backend/api';

// axios.defaults.baseURL = 'http://localhost/work/remote-alola-backend/api';
//live url
axios.defaults.baseURL = 'https://manage.firstcard.sa/api';


// and running it somewhere here
interceptorsSetup()

Vue.use(VueLoaders);
Vue.use(VueGeolocation);
Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyDxl30OMsze17Ha_1gUZ0R0ZOG2FwCqsno',
        libraries: 'places', // This is required if you use the Autocomplete plugin
    }

});
//AIzaSyBoRARUhyhkwbdrJvn6HH0OVm3k2yyo5RM
//prod AIzaSyDxl30OMsze17Ha_1gUZ0R0ZOG2FwCqsno
Vue.use(Geocoder, {
    defaultCountryCode: null, // e.g. 'CA'
    defaultLanguage:    null, // e.g. 'en'
    defaultMode:        'lat-lng',
    googleMapsApiKey:   'AIzaSyDxl30OMsze17Ha_1gUZ0R0ZOG2FwCqsno'
});


Vue.use(VueRouter);
Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');