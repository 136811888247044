<template>
  <div>
    <!-- Slider -->
    <section class="slide-home">
      <div class="container">
        <hooper style="height:auto" :settings="hooperSettings" :infiniteScroll="true">
          <slide v-for="(slide, indx) in gallery" v-if="slide.web_image !== 'https://manage.firstcard.sa/public/images/no_image.png'" :key="indx" :index="indx">
            <img  :src="slide.web_image" style="width: 100%" height="100%">
          </slide>
          <hooper-navigation slot="hooper-addons"></hooper-navigation>
        </hooper>
      </div>
    </section>
  </div>
</template>
<script>
import {mapGetters} from 'vuex';
import { Hooper, Slide, Navigation as HooperNavigation} from 'hooper';
import 'hooper/dist/hooper.css';

export default {
  name: 'Slider',
  components: {
    Hooper,
    Slide,
    HooperNavigation
  },
  data() {
    return {
      sliders:[],
      hooperSettings: {
        itemsToShow: 1,
        centerMode: true,
        breakpoints: {
          800: {
            centerMode: false,
            itemsToShow: 1
          },
          1000: {
            itemsToShow: 1,
            pagination: 'fraction'
          }
        }
      }
    }
  },
  computed:{
    ...mapGetters(['gallery'])
  },


}
</script>
