import axios from 'axios';
import store from '../store'
const state = {
    categoryItems: [],
    cities:[]
}

const getters = {
    category_items: (state) => state.categoryItems,
    allCities: (state) => state.cities
}

const actions = {
    async fetchCategoryItems({ commit }, data) {
        const response = await axios.get('/category/' + data.category_id+ '?page=' + data.page + '&lat=' + data.lat + '&lng='+ data.lng + '&selected_cities='+ data.selected_cities );

        if(!response.data.Data) {
            store.commit('initLastPage', true)
        }else {
            store.commit('initLastPage', false)
        }

        if(data.first) {
            commit('initCategoryItems', response.data.Data);
        }else if(!data.first && response.data.Data) {
            commit('addCategoryItems', response.data.Data);
        }
    },
    async fetchCities({commit}) {
        const response = await axios.get('/getCategoryFilterData/1');
        commit('initCities', response.data.Data);
    }
}

const mutations = {
    initCategoryItems: (state, data) => (state.categoryItems = data),
    addCategoryItems: (state, data) => (state.categoryItems = state.categoryItems.concat(data)),
    initCities : (state,data) => (state.cities = data)

}

export default {
    state,
    getters,
    mutations,
    actions
}