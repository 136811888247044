import axios from 'axios';

const state = {
    near_stores: [],
}

const getters = {
    getNearStores :(state) => state.near_stores,
}

const actions = {
    async fetchNearStores({ commit }, data) {
        const response = await axios.get('/nearby-stores?lat=' + data.lat + '&lng=' + data.lng);
        commit('initNearStores', response.data.Data);
    }
}

const mutations = {
    initNearStores: (state, data) => (state.near_stores = data)
}

export default {
    state,
    getters,
    mutations,
    actions
}