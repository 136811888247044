<template>
  <div v-if="loaded">
    <store-slider></store-slider>
    <!-- Tab panes -->
    <!-- Locality -->
    <section class="locality">
      <div class="container">
        <div class="title">
          <h3 class="font-weight-bold">المراكز</h3>
        </div>
        <div class="row">
          <!-- Nav tabs -->
          <ul class="nav nav-tabs flex-column col-lg-2" role="tablist">
            <li class="nav-item">
              <a class="nav-link active" ref="home_tab"  data-toggle="tab" href="#home" role="tab">التفاصيل</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-toggle="tab" href="#offers" role="tab">
                العروض
              <span class="badge badge-light">{{storeOffers.length}}</span>
              </a>

            </li>
            <li class="nav-item">
              <a class="nav-link" ref='branch' data-toggle="tab" href="#branch" role="tab">
                الفروع
                <span class="badge badge-light">{{ storeBranches.length }}</span>
              </a>
            </li>
          </ul>
          <div class="tab-content locality-info col-lg-10 col-sm-12">
            <my-store></my-store>
            <store-offer></store-offer>
            <store-branch></store-branch>
          </div>

        </div>
      </div>
    </section>
  </div>
</template>
<script>
import StoreSlider from '../components/store/Slider'
import StoreOffer from '../components/store/Offer'
import StoreBranch from '../components/store/Branch'
import MyStore from '../components/store/Store'
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'Store',
  components: {
    'store-slider': StoreSlider,
    'store-offer': StoreOffer,
    'store-branch': StoreBranch,
    'my-store': MyStore
  },
  data() {
    return {
      store_id: '',
      loaded: false
    }
  },
  watch: {
    '$route'(to) {
      this.store_id = to.params.id;
      this.fetchStore(this.store_id).then(() => {
        this.loaded = true
        this.$refs.home_tab.classList.value = this.$refs.home_tab.classList.value + ' active';
        this.$refs.branch.classList.value = 'nav-link';

        document.getElementById('home').classList.value = 'tab-pane active'
        document.getElementById('branch').classList.value = 'tab-pane'


      });
    }
  },
  computed: {
    ...mapGetters(['getStore', 'storeBranches', 'storeOffers']),
  },
  created() {
    this.store_id = this.$route.params.id
    this.fetchStore(this.store_id).then(() => {
      this.loaded = true
    });
  },
  methods: {
    ...mapActions(['fetchStore'])
  }
}
</script>

<style>

</style>