import axios from 'axios';
import store from '../store/store'

export default function setup() {
    axios.interceptors.request.use(function (config) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        store.commit('changeLoader', true);
        return config;
    }, function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        store.commit('changeLoader', false);
        return Promise.reject(error);
    });

    axios.interceptors.response.use(function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        store.commit('changeLoader', false);
        return response;
    }, function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        store.commit('changeLoader', false);
        return Promise.reject(error);
    });
}