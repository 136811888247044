<template>
    <div v-if="loaded">
        <carousel :per-page='perpage' :spacePadding='2' :navigate-to="3" :navigationClickTargetSize='0' :loop="true" :mouse-drag="true"  :centerMode='true' >
            <slide v-for="category in cats" :key="category.id" >
                <li class="nav-item mt-5"
                    style="font-size:14px; list-style-type:none"
                    >
                    <router-link class="nav-link cat-link-1" :to="'/'+ category.id+ '/category'"
                    >
                        <div class="cate-div-img  m-auto" style="background-color:#35495e">
                            <img :src="category.image" alt="">
                        </div>
                        <p class="text-center mt-2" style="color: black">{{category.alias_name}}</p>
                    </router-link>
                </li>
            </slide>
        </carousel>
        <br >
        <nav class="navbar navbar-toggleable-md navbar-light sub-menu" style="display: none;">
            <button class="navbar-toggler navbar-toggler-left" type="button" data-toggle="collapse"
                    data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                    aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <a class="hidden-sm-up navbar-brand text-right" href="#">الاقسام</a>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">

                <ul class="navbar-nav">
                    <li class="nav-item text-center"
                        style="width: 78px; height: 100%; font-size:14px; "
                        v-for="category in homeCategories" :key="category.id">
                        <router-link class="nav-link cat-link-1" :to="'/'+ category.id+ '/category'"
                        style="margin-left: 1rem !important;">
                            <div class="cate-div-img" style="background-color:#35495e">
                                <img :src="category.image" alt="">
                            </div>
                            <span>{{category.alias_name}}</span>
                        </router-link>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex';
    import { Carousel, Slide } from 'vue-carousel';

    export default {
         components: {
        Carousel,
        Slide
        },
        name: 'Header',
        data() {
            return {
                loaded:false,
                width:'',
                search: '',
                keyword: '',
                perpage: 15,
                cats:[]
            }
        },
        created() {
            this.getScreenRatio();
            this.cats = [1,2,3,4,5,6,7,8,9,1,2,3,4,5,6];
        },
        mounted() {
          this.loaded = true;
        },
        computed: {
            ...mapGetters(['homeCategories']),
        },
        watch:{
          homeCategories() {
            this.cats = this.homeCategories;
          }
        },
        methods: {
            ...mapActions(['setKeyWord']),
            offerSearch() {
                if (this.keyword !== this.search || this.$route.name !== 'my_search') {
                    this.keyword = this.search
                    this.$router.push({name: 'my_search', params: {'keyword': this.keyword}})
                }
            }
        ,
        getScreenRatio(){
            const height =  window.screen.availHeight
            this.width =  window.screen.availWidth
            if (height <= 876 && this.width <= 474 ) {
                this.perpage = 4
            } else {
                this.perpage = 15
            }
        },
          navigateCarsoul() {
              return this.count = 3;
          }
        }
    }
</script>

<style scoped>
>>> .VueCarousel-dot-container{
    margin: 0px !important;
    /* direction: rtl !important; */

}
>>> .VueCarousel{
    /* direction: rtl !important; */
}
>>> .VueCarousel-pagination{
    /* direction: rtl !important; */
}
>>> .VueCarousel-inner--center{
    flex-basis:78.6px !important;
}

    .cate-div-img {
        border-radius: 50%;
        width: 45px;
        height: 45px;
        margin-bottom: 5px;
    }
    .cate-div-img img {
      margin: auto;
      padding: 32%;

    }
    @media only screen and (min-width: 1024px) {

        .custom-search {
            position: absolute;
            right: 83%;
            z-index: 999;
        }

        .custom-search-input {
            width: 77% !important;
        }
    }

    @media only screen and (min-width: 768px) and  (max-width: 1024px) {

        .custom-search {
            position: absolute;
            right: 86%;
            z-index: 999;
        }

        .custom-search-input {
            width: 88% !important;
        }
    }

    @media only screen and (min-width: 540px) and  (max-width: 720px) {

        .custom-search {
            position: absolute;
            right: 84%;
            z-index: 999;
        }

        .custom-search-input {
            width: 77% !important;
        }

    }


    @media only screen and (min-width: 412px) and (max-width: 731px) {
        .custom-search {
            position: absolute;
            right: 83%;
            z-index: 999;
        }

        .custom-search-input {
            width: 79% !important;
        }
    }

    @media only screen and (min-width: 360px) and  (max-width: 411px) {
        .custom-search {
            position: absolute;
            right: 75%;
            z-index: 999;
        }

        .custom-search-input {
            width: 79% !important;
        }
    }

    @media only screen and (min-width: 320px) and  (max-width: 360px) {

        .custom-search {
            position: absolute;
            right: 72%;
            z-index: 999;
        }

        .custom-search-input {
            width: 77% !important;
        }

    }

    @media only screen and (min-width: 320px) and  (max-width: 720px) {

      li {
        width: 100% !important;
      }

      slide {
          display: flex;
          justify-content: center;
      }
    }


</style>