<template>
    <section class="resturant" v-infinite-scroll="loadMore" infinite-scroll-disabled="busy"
             infinite-scroll-immediate-check="false" infinite-scroll-distance="10">
        <div class="container">
            <div class="title mb-5">
                <h3 class="font-weight-bold">نتائج البحث</h3>
            </div>
            <div class="row">
              <div class="col-lg-3 col-sm-6 div-height" v-for="item in getSearchOffers" :key="item.id">
                <div class="card mb-3 card-height">
                  <img class="card-img-top img-height"
                       :src="item.image"
                       data-holder-rendered="true">
                  <div class="card-body p-2 content-height">
                    <div class="d-flex justify-content-between align-items-center header" style="height: 25%">
                      <h5 class="card-title  text-right mt-4">
                        <router-link :to="'/' + item.id + '/store'">
                          {{ item.name }}
                        </router-link>

                      </h5>
                    </div>

                    <div class="d-flex justify-content-between align-items-center location mt-3" style="height: 60%">
                      <p style="width: 70%">
                        {{item.address.substring(0, 50)}}
                      </p>
                      <p style="width: 25%">
                        <span class="arround">يبعد عنك</span>
                        <span>{{ ' ' + item.distance + ' ' }} km</span>
                      </p>
                    </div>
                    <div class="d-flex card-footer justify-content-between align-items-center">
                      <a href="#" class="share">
                        <span class="fa-share-alt fas " aria-hidden="true"></span>
                      </a>
                      <router-link :to="'/'+ item.id + '/store/map'" class="map">
                        <img :src="'/assets/images/location-pin.png'" alt="">
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
    </section>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex'
    import infiniteScroll from "vue-infinite-scroll";

    export default {
        name: 'SearchOffer',
        directives: {infiniteScroll},
        data() {
            return {
                keyword: '',
                data: {},
                truncate_name: true,
                truncate_address: true,
            }
        },
        computed: {
            ...mapGetters(['myGeoLocation', 'getSearchOffers', 'get_is_last'])
        },
        watch: {
            '$route'(to) {
                this.data.keyword = to.params.keyword
                this.data.cat_id = 0
                this.data.lat = this.myGeoLocation.lat
                this.data.lng = this.myGeoLocation.lng
                this.data.page = 1
                this.data.first = true
                this.offer_search(this.data);
            }
        },
        created() {
            this.data.keyword = this.$route.params.keyword;
            this.data.cat_id = 0
            this.data.lat = this.myGeoLocation.lat
            this.data.lng = this.myGeoLocation.lng
            this.data.page = 1
            this.data.first = true
            this.offer_search(this.data).then(() => {
            });
        },
        methods: {
            ...mapActions(['offer_search']),
            loadMore() {
                this.data.page = this.data.page + 1
                this.data.first = false
                if (!this.get_is_last) {
                    this.offer_search(this.data);
                }
            },
            truncate(value) {
                this.truncate_name = value;
            },
            truncateAddress(value) {
                this.truncate_address = value;
            },
        }
    }
</script>

<style scoped>
  .img-height {
    height: 60%;
  }
  .card-height{
    height: 95%;
  }
  .div-height{
    height: 500px;
  }
  .content-height{
    height: 30%;
  }
  .resturant .card .card-footer a{
    margin: 1px 25px;
  }
</style>