<template>
  <header class="navbar navbar-toggleable-md navbar-light bg-white fixed-top mediumnavigation menuOula">
    <button  class="navbar-toggler navbar-toggler-left collapsed" type="button" data-toggle="collapse"
            data-target="#navbarsWow" aria-controls="navbarsWow" aria-expanded="false"
            aria-label="Toggle navigation">
      <i class="fa fa-navicon"></i>
    </button>
    <div class="container">
      <!-- Begin Logo -->
      <router-link class="navbar-brand" to="/">
        <img :src="'/assets/images/logo.png'" alt="Affiliates - Free Bootstrap Template">
      </router-link>
      <!-- End Logo -->
      <!-- Begin Menu -->
      <div ref="nav_collapse" class="collapse navbar-collapse col-auto" id="navbarsWow">
        <!-- Begin Menu -->
        <ul class="navbar-nav ml-auto" style="margin-top: 1%">
          <li class="nav-item dropdown">
            <router-link class="nav-link" :to="'/'"
               aria-haspopup="true" aria-expanded="false">
              الرئيسية
              <img :src="'/assets/images/icons/home.png'" alt="">
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="'/near-you'"
               aria-haspopup="true" aria-expanded="false">
              بالقرب منك
              <img :src="'/assets/images/icons/location.png'" alt="">
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="'/request-card'" class="nav-link"
               aria-haspopup="true" aria-expanded="false">
              طلب بطاقة
              <img width="22" height="22" :src="'/assets/images/icons/icons8-credit-card-50.png'" alt="">
            </router-link>
          </li>
          <li class="nav-item dropdown">
            <router-link :to="'/contact-us'" class="nav-link"
               aria-haspopup="true" aria-expanded="false">
              من نحن
              <img width="22" height="22" :src="'/assets/images/icons/icons8-info-32.png'" alt="">
            </router-link>
          </li>
        </ul>
          <ul class="list-inline ml-auto icon-list" >
            <li class="nav-item" v-for="(social,index) in getSocial"
                v-if="index<4"
                style="color:red"
                :key="social.id">

              <a class="nav-link" :href="social.content"  target="_blank">
                <img :src="social.image" width="30" alt="">
              </a>
            </li>

          </ul>
<!--          <li class="nav-item d-flex">-->
<!--            <a class="nav-link" href="#"-->
<!--               aria-haspopup="true" aria-expanded="false">-->
<!--              <img src="assets/images/icons/egypt.png" alt="">-->
<!--              English-->
<!--            </a>-->
<!--          </li>-->

        <!-- End Menu -->
      </div>
    </div>
  </header>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
export default {
  name: 'Nav',
  computed:{
    ...mapGetters(['getSocial']),
    // socials:{
    //   return this.getSocial
    // }
  },
  created() {
    this.fetchSocial();
  },
  watch: {
    '$route'() {
      this.$refs.nav_collapse.classList.value = 'collapse navbar-collapse col-auto';
    }
  },
  methods:{
    ...mapActions(['fetchSocial'])
  }
}
</script>

<style>
  .icon-list{
    display: inline-flex;
  }


</style>