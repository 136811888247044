<template>
  <div class="tab-pane" id="branch" role="tabpanel">
    <!-- Branches -->
    <div class="info-branch">
      <!-- start item -->
      <div class="item-branch" v-for="branch in storeBranches" :key="branch.id">
        <div class="row">
          <div class="col-lg-2 col-sm-12">
            <router-link :to="'/'+branch.id + '/store'">
            <img :src="branch.image" alt="">
            </router-link>
          </div>
          <div class="col-lg-10 col-sm-12">
            <!-- start address -->
            <div class="branch-info">
              <div class="row">
                <div class="col-1">
                  <p class="branch-title">العنوان</p>
                </div>
                <div class="col-11">
                  <p class="branch-details">{{branch.address}}</p>
                </div>
              </div>
            </div>
            <!-- start Phone -->
            <div class="branch-info">
              <div class="row">
                <div class="col-1">
                  <p class="branch-title">هاتف</p>
                </div>
                <div class="col-11">
                  <p class="branch-details"> {{ branch.phone }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
  export default  {
    name: 'storeBranch',
    data(){
      return {

      }
    },

    computed:{
      ...mapGetters(['storeBranches'])
    }
  }
</script>

<style>

</style>