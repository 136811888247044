<template>
  <div class="tab-pane active"  id="home" role="tabpanel">
    <!-- Info -->
    <div class="title">
      <h2>
        {{ getStore.name }}
      </h2>
    </div>
    <div class="info-details">
      <div class="contasiner">
        <div class="row">

          <div class="col-lg-4 col-sm-12">
            <h5 class="info-title note">كل التفاصيل</h5>
            <img class="description_img" :src="'/assets/images/outline.jpg'">
            <ul v-for="(not,index) in getStore.description_notes" :key="index">
              <li>{{not.note}}</li>
            </ul>
          </div>
          <div class="col-lg-4 col-sm-12 discount-service text-right">
            <h5 class="info-title go-right">الخدمة</h5>
            <ul v-for="(description,index) in getStore.store_description" :key="description.id">
              <li class="go-right" :class="{'bottom-border': getStore.store_description.length !== index+1}">{{ description.service }}</li>
            </ul>
          </div>

          <div class="col-lg-4 col-sm-12 discount-value">
            <h5 class="info-title">الخصم</h5>
            <ul v-for="(description,index) in getStore.store_description" :key="description.id">
              <li :class="{'bottom-border': getStore.store_description.length !== index+1}">{{ description.value }}</li>
            </ul>
          </div>
        </div>
      </div>

    </div>
    <!-- Offers -->
    <div class="title" v-if="storeOffers.length > 0">
      <h2>
        كل العروض
      </h2>
    </div>
    <div class="info-offers" v-for="offer in storeOffers" :key="offer.id">
      <!-- start item -->
      <div class="item">
        <div class="row">
          <div class="col-2">
            <img :src="offer.image" alt="">
          </div>
          <div class="col-xs-3">
            <p class="offer-title">{{ offer.title }}</p>
            <p class="offer-price"> السعر قبل {{ offer.offer_before_price }} ريال</p>
            <p class="offer-price"> السعر بعد {{ offer.offer_after_price }} ريال</p>
            <p class="offer-desc">{{ offer.description }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- Address & Location & Map -->
    <div class="details container d-inline">
      <div class="row">
        <div class="col-lg-8 col-sm-12">
          <!-- Address -->
          <div class="title">
            <h2>
              العنوان
            </h2>
          </div>
          <div class="info-address">
            <p>
              {{ getStore.address }}
            </p>
          </div>

          <!-- Phone -->
          <div class="title">
            <h2>
              الهاتف
            </h2>
          </div>
          <div class="info-address">
            <p>
              {{ getStore.phone }}
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-sm-12">
          <div class="info-map" id="info-map">
            <GmapMap
                :center="center"
                :zoom="17"
                style="width:100%; height: 180px"
            >
              <GmapMarker
                  :key="index"
                  v-for="(m, index) in markers"
                  :position="m.position"
                  @click="openMap(m.position)"
              />
            </GmapMap>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from 'vuex';

export default {
  name: 'Store',
  data() {
    return {
      center: {},
      markers: []
    }
  },
  computed: {
    ...mapGetters(['getStore', 'storeOffers'])
  },
  created() {
    this.center.lat = parseFloat(this.getStore.lat);
    this.center.lng = parseFloat(this.getStore.lng);
    let pos = {position: this.center}
    this.markers.push(pos)
  },
  mounted() {
    if (this.$route.params.key) {
      this.scrollToElement();
    }
  },
  methods: {
    scrollToElement() {
      let el = document.getElementById("info-map")
      el.scrollIntoView({behavior: "smooth"});
    },
    openMap(position) {
      let location = 'https://www.google.com.eg/maps/search/?api=1&query=' + position.lat + ',' + position.lng;
      window.open(location, '_blank');
    }
  }
}
</script>

<style scoped>

.go-right {
padding-right: 5%;
  text-align: right !important;
}

.offer-right {
  text-align: right;
  padding-right: 35px;
}

.offer-right h4 {
  font-weight: bold;
}

.offer-left {
  text-align: center
}

.offer-left h4 {
  font-weight: bold;
}

.left-block {
  padding: 20px 0px 20px 16px;
  border-right: 1px solid #D4D4D4 !important;
}

.right-block {
  padding: 20px 50px 20px 0px;
}

.note {
  border-bottom: 0px !important;
}

.discount-service {
  padding: 0 0 0 0;
  border-right: 1px solid #D4D4D4 !important;
}

.discount-value {
  padding-right: 0px;
}
.description_img {
  position: relative;
  padding: 10% 0 10% 0;
  right: 40%;
}
.bottom-border {
  border-bottom: 1px solid #D4D4D4 !important;
}

@media only screen and (min-width: 360px) and (max-width: 499px) {
  .discount-service {
    border-right: 0px solid #D4D4D4 !important;
    margin:0 4% 0 4% ;
  }

  .discount-value {
    margin:0 4% 0 0 ;
  }

  .description_img {

    padding: 10% 0 10% 0;
    right: 10%;
  }
}

@media only screen and (min-width: 500px) and (max-width: 568px) {
  .discount-service {
    border-right: 0px solid #D4D4D4 !important;
    margin:0 4% 0 4% ;
  }

  .discount-value {
    margin:0 4% 0 0 ;
  }

  .description_img {

    padding: 10% 0 10% 0;
    right: 44%;
  }
}

@media only screen and (min-width: 569px) and (max-width: 700px) {
  .discount-service {
    border-right: 0px solid #D4D4D4 !important;
    padding:0 3% 0 3% ;
  }

  .discount-value {
    padding:0 3% 0 3% ;
  }

  .description_img {

    padding: 10% 0 10% 0;
    right: 44%;
  }
}

@media only screen and (min-width: 701px) and (max-width: 991px) {
  .discount-service {
    border-right: 0px solid #D4D4D4 !important;
    padding:0 3% 0 3% ;
  }

  .discount-value {
    padding:0 3% 0 3% ;
  }

  .description_img {

    padding: 10% 0 10% 0;
    right: 46%;
  }
}
</style>