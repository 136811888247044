export default {
    name: "GeneralInfo",
    data() {
        return {
            support: {
                phone: '',
                email: '',
                address: '',
            },
        }
    },
    methods: {
        getSupportData() {
            this.$http.get('/support')
                .then(response => {
                    this.support = response.data.Data;
                })
                .catch(err => {
                    console.log(err.data)
                });
        },
    }
}